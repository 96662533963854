<template>
<div>
    <section id="knowledge-base-content">
        <b-row>
            <b-col :cols="3">
                <b-card no-body>
                    <b-card-header class="pb-50">
                        <h5>Filtreleme</h5>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Sınıf</label>
                                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedClass" :options="classDatas" class="w-100" :getOptionLabel="(option) => option.name" :reduce="(val) => val.id" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Ders</label>
                                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="(option) => option.name" :reduce="(val) => val.id" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Alt Sınıf</label>
                                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedChildClass" :options="childClass" class="w-100" :getOptionLabel="(option) => option.name" :reduce="(val) => val.id" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Alt Ders</label>
                                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedChildLesson" :options="childLessons" class="w-100" :reduce="(val) => val.id" label="name" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Başlangıç Tarihi</label>
                                <flat-pickr v-model="startDate" class="form-control" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Bitiş Tarihi</label>
                                <flat-pickr v-model="endDate" class="form-control" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <b-button variant="success" @click="getData()" style="marginTop:10px;width:100%">FİLTRELE</b-button>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col :cols="9">
                <vue-apex-charts v-if="!showLoading" type="treemap" height="500" width="100%" :options="chartOptionsComputed" :series="chartData" />
                <vue-good-table theme="polar-bear" :rows="filteredData" :columns="fields" :line-numbers="true" :sort-options="{
              enabled: false,
            }">
                    <div slot="emptystate" class="text-nowrap text-center">
                        Kayıt Bulunamadı
                    </div>
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'count'" class="text-nowrap">
                            <span class="font-weight-bolder mb-12">{{
                                props.row.count
                              }}&nbsp;({{ props.row.count > 0 ?  parseInt(props.row.count / props.row.homeWorkCount): 0 }})</span>
                        </div>
                        <div v-else-if="props.column.field === 'successPercent'" class="text-nowrap">
                            <span class="font-weight-bolder mb-12">
                                {{
                                    props.row.successPercent != "NaN"? 
                                    props.row.successPercent.toFixed(0): 0
                                }}%
                            </span>
                        </div>
                    </template>
                </vue-good-table>
            </b-col>
        </b-row>
    </section>
    <b-modal v-model="showLoading" centered hide-footer hide-header>
        <p class="text-center">
            <b>Rapor Oluşturuluyor!<br />Lütfen Bekleyin...</b>
        </p>
    </b-modal>
</div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay,
    BCardText,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import VueApexCharts from "vue-apexcharts";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
export default {
    components: {
        VueGoodTable,
        VueApexCharts,
        flatPickr,
        vSelect,
        AppCollapse,
        AppCollapseItem,
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BProgress,
        BOverlay,
        BCardText,
    },
    data() {
        return {
            chartData: [{
                data: [

                ],
            }, ],
            chartOptionsComputed: {
                legend: {
                    show: false,
                },
                chart: {
                    height: 350,
                    type: "treemap",
                },
                title: {
                    text: "Ödev Dağılımı",
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: "12px",
                    },
                    formatter: function (text, op) {
                        return [text, "%" + op.value];
                    },
                    offsetY: -4,
                },
                plotOptions: {
                    treemap: {
                        enableShades: true,
                        shadeIntensity: 0.5,
                        reverseNegativeShade: true,
                        colorScale: {
                            ranges: [{
                                    from: 0,
                                    to: 50,
                                    color: "#CD363A",
                                },
                                {
                                    from: 50,
                                    to: 100,
                                    color: "#52B12C",
                                },
                            ],
                        },
                    },
                },
            },
            showLoading: false,
            definition: "",
            startDate: "",
            endDate: "",
            show: false,
            loading: false,
            selectedClass: null,
            selectedChildClass: null,
            selectedLesson: null,
            selectedChildLesson: null,
            selectedTopic: null,
            selectedVideoGroup: null,
            selectedTest: null,
            selectedVideo: null,
            startDate: null,
            endDate: null,
            users: [],
            classDatas: [],
            childClassDatas: [],
            lessons: [],
            childClass: [],
            childLessons: [],
            videoGroups: [],
            topics: [],
            tests: [],
            videos: [],
            filterText: "",
            filterRequest: {
                lessnId: "",
                childClassId: "",
                childLessonId: "",
                status: 5,
            },
            filteredData: [],
            fields: [
                { field: "name", label: "Başlık" },
                { field: "homeWorkCount", label: "Toplam Ödev Sayısı" },
                { field: "count", label: "Toplam Öğrenci Sayısı" },
                { field: "trueCount", label: "Toplam Ödev Yapan Öğrenci Sayısı" },
                { field: "successPercent", label: "Başarı Yüzdesi" },
            ],
        };
    },
    created() {
        this.getClass();
        this.getChildClass();
        this.getLesson();
    },
    watch: {
        selectedClass: function (val) {
            this.childClass = this.childClassDatas.filter(x => x.parentClass != null && x.parentClass.id == val);
            //this.getData();
        },
        selectedChildClass: function (val) {
            //this.getData();
        },
        selectedLesson: function (val) {
            this.getChildLesson();
            //this.getData();
        },
        selectedChildLesson: function () {
            //this.getTopics();
        },
        selectedTopic: function () {
            //this.getVideoGroup();
        },
        selectedVideoGroup: function () {
            //this.getTests();
            //this.getVideos();
        },
    },
    methods: {
        async getClass() {
            var lesson = await this.$http.get("Branch/Classes");
            this.classDatas = lesson.data.data;
        },
        async getChildClass() {
            var lesson = await this.$http.get("Branch/ChildClasses");
            this.childClassDatas = lesson.data.data;
            this.childClass = lesson.data.data;
        },
        async getLesson() {
            var lesson = await this.$http.get("Branch/Lessons");
            this.lessons = lesson.data.data;
        },
        async getChildLesson() {
            var childLesson = await this.$http.get(
                "Branch/Lessons/" + this.selectedLesson
            );
            this.childLessons = childLesson.data.data;
        },
        async getData() {
            var stringPath = "Branch/HomeWorkHierarchies";
            /*
                    public Guid ClassId { get; set; }
        public Guid? ChildClassId { get; set; }
        public Guid? LessonId { get; set; }
        public Guid? ChildLessonId { get; set; }
        public Guid? TopicId { get; set; }
        public Guid? VideoGroupId { get; set; }
        public DateTime? StartAt { get; set; }
        public DateTime? EndAt { get; set; }

            */
            var request = {};
            if (this.selectedClass == null) return;
            if (this.selectedClass != null) {
                request.classId = this.selectedClass;
            }
            if (this.selectedLesson != null) {
                request.lessonId = this.selectedLesson;
            }
            if (this.selectedChildClass != null) {
                request.childClassId = this.selectedChildClass;
            }
            if (this.selectedChildLesson != null) {
                request.childLessonId = this.selectedChildLesson;
            }
            if (this.selectedTopic != null) {
                request.topicId = this.selectedTopic;
            }
            if (this.selectedVideoGroup != null) {
                request.videoGroupId = this.selectedVideoGroup;
            }
            if (this.startDate != null) {
                request.startAt = this.startDate;
            }
            if (this.endDate != null) {
                request.endAt = this.endDate;
            }
            this.showLoading = true;
            var chartData = await this.$http.post("Branch/HomeWorkHierarchies", request);
            this.filteredData = chartData.data.data;
            this.chartData[0].data = chartData.data.data.map(function (element) {
                if (element.percent == 0) return { x: "", y: 0 };
                return {
                    x: element.name,
                    y: element.percent,
                };
            });
            this.showLoading = false;
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/pages/page-knowledge-base.scss";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
